.tags {
    display: flex;
    flex-wrap: nowrap;
    gap: 10px;
    @media screen and (max-width: 600px) {
        flex-wrap: wrap;
    }
    @media screen and (min-width: 601px) and (max-width: 992px) {
        flex-wrap: wrap;
    }

    &__item {
        display: flex;
        height: 25px;
        min-width: 115px;
        box-sizing: border-box;
        justify-content: center;
        align-items: center;
        background-color: $primaryColor;
        border-radius: 10px;
        color: white;
        padding: 3px 15px;
        font-size: 14px;
        @media screen and (max-width: 600px) {
            height: 18px;
            min-width: 90px;
            border-radius: 5px;
            padding: 2px 15px;
            font-size: 10px;
        }
        @media screen and (min-width: 601px) and (max-width: 992px) {
            height: 22px;
            border-radius: 7px;
            font-size: 12px;
        }
    }
}
