.about__description {
    margin: 0 108px 31px 108px;
    display: flex;
    flex-direction: column;
    gap: 31px;
    @media screen and (max-width: 600px) {
        margin: 0;
        gap: 20px;
    }
    @media screen and (min-width: 601px) and (max-width: 992px) {
        margin: 0;
        gap: 25px;
    }
}
