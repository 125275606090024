body {
    font-family: Montserrat, sans-serif;
    font-weight: 500;
}

body,
ul,
li,
p,
h1,
h2,
h3 {
    margin: 0;
    padding: 0;
    font-weight: 500;
}

.page-container {
    max-width: 1240px;
    margin: 0 auto;
    padding: 40px 100px 100px 100px;
    @media screen and (max-width: 600px) {
        padding: 20px 20px 60px 20px;
    }
    @media screen and (min-width: 601px) and (max-width: 992px) {
        padding: 30px 50px 30px 50px;
    }
}
