.gallery-item {
    background-color: $primaryColor;
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    aspect-ratio: 1/1;
    @media screen and (max-width: 600px) {
        aspect-ratio: unset;
        height: 255px;
    }

    &__title {
        display: flex;
        align-items: flex-end;
        padding: 20px;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        top: 0;
        color: white;
        font-size: 18px;
        background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
    }

    &__img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
