.slideshow {
    position: relative;
    height: 415px;
    margin-bottom: 30px;
    @media screen and (max-width: 600px) {
        height: 255px;
        margin-bottom: 15px;
    }
    @media screen and (min-width: 601px) and (max-width: 992px) {
        height: 330px;
        margin-bottom: 22px;
    }

    &__img {
        border-radius: 25px;
        height: 100%;
        width: 100%;
        object-fit: cover;
        @media screen and (max-width: 600px) {
            border-radius: 10px;
        }
        @media screen and (min-width: 601px) and (max-width: 992px) {
            border-radius: 17px;
        }
    }
    &__navigation {
        position: absolute;
        top: 0;
        right: 23px;
        bottom: 0;
        left: 23px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        @media screen and (max-width: 600px) {
            right: 6px;
            left: 6px;
        }
        @media screen and (min-width: 601px) and (max-width: 992px) {
            right: 15px;
            left: 15px;
        }
    }
    &__left,
    &__right {
        height: 79px;
        width: 47px;
        cursor: pointer;
        @media screen and (max-width: 600px) {
            height: 20px;
            width: 12px;
        }
        @media screen and (min-width: 601px) and (max-width: 992px) {
            height: 50px;
            width: 30px;
        }
    }
    &__right {
        transform: rotate(180deg);
    }
    &__numerotation {
        position: absolute;
        bottom: 25px;
        left: 0;
        right: 0;
        text-align: center;
        font-size: 18px;
        color: white;
        @media screen and (max-width: 600px) {
            display: none;
        }
    }
}
