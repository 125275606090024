footer {
    color: white;
    background-color: black;
    text-align: center;
    padding: 66px 0 33px 0;
    @media screen and (max-width: 600px) {
        padding: 62px 0;
    }
    p {
        margin-top: 47px;
        font-size: 24px;
        @media screen and (max-width: 600px) {
            margin-top: 34px;
            font-size: 12px;
        }
    }
}
