.banner {
    position: relative;
    height: 223px;
    border-radius: 25px;
    overflow: hidden;
    margin-bottom: 43px;
    @media screen and (max-width: 600px) {
        border-radius: 10px;
        margin-bottom: 22px;
    }
    @media screen and (min-width: 601px) and (max-width: 992px) {
        border-radius: 20px;
    }

    &__img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &__title {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
        font-size: 48px;
        color: white;
        @media screen and (max-width: 600px) {
            padding-left: 16px;
            padding-right: 102px;
            justify-content: flex-start;
            text-align: left;
            font-size: 24px;
        }
        @media screen and (min-width: 601px) and (max-width: 992px) {
            font-size: 36px;
        }
    }
}
