.stars {
    display: flex;
    gap: 15px;
    justify-content: flex-end;
    @media screen and (max-width: 600px) {
        gap: 6px;
    }
    @media screen and (min-width: 601px) and (max-width: 992px) {
        gap: 10px;
    }

    &__item {
        height: 24px;
        @media screen and (max-width: 600px) {
            height: 13px;
        }
    }
}
