.accomodation {
    display: flex;
    flex-direction: column;

    &__info {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: 24px;
        @media screen and (max-width: 600px) {
            flex-direction: column;
            align-items: stretch;
            margin-bottom: 16px;
            gap: 16px;
        }
        @media screen and (min-width: 601px) and (max-width: 992px) {
            flex-direction: column;
            align-items: stretch;
            margin-bottom: 16px;
            gap: 16px;
        }
    }

    &__title {
        color: $primaryColor;
        h1 {
            font-size: 36px;
            @media screen and (max-width: 600px) {
                font-size: 18px;
                margin-bottom: 9px;
            }
            @media screen and (min-width: 601px) and (max-width: 992px) {
                font-size: 27px;
            }
        }
        p {
            font-size: 18px;
            margin-bottom: 24px;
            @media screen and (max-width: 600px) {
                font-size: 14px;
                margin-bottom: 12px;
            }
            @media screen and (min-width: 601px) and (max-width: 992px) {
                font-size: 19px;
            }
        }
    }

    &__host {
        display: flex;
        flex-direction: column;
        gap: 27px;
        @media screen and (max-width: 600px) {
            flex-direction: row-reverse;
            justify-content: space-between;
            align-items: center;
        }
        @media screen and (min-width: 601px) and (max-width: 992px) {
            flex-direction: row-reverse;
            justify-content: space-between;
            align-items: center;
        }
    }

    &__description {
        display: flex;
        align-items: flex-start;
        gap: 76px;
        @media screen and (max-width: 600px) {
            flex-direction: column;
            gap: 20px;
        }
        @media screen and (min-width: 601px) and (max-width: 992px) {
            flex-direction: column;
            gap: 40px;
        }
    }

    // modifie les classes SCSS du composant Collapse
    .collapse {
        width: 50%;
        border-radius: 10px;
        @media screen and (max-width: 600px) {
            width: 100%;
            border-radius: 5px;
        }
        @media screen and (min-width: 601px) and (max-width: 992px) {
            width: 100%;
            border-radius: 5px;
        }
        &__header {
            padding: 13px 13px 13px 20px;
            border-radius: 10px;
            @media screen and (max-width: 600px) {
                padding: 5px 7px 5px 11px;
                border-radius: 5px;
            }
        }
        &__title {
            font-size: 18px;
            @media screen and (max-width: 600px) {
                font-size: 13px;
            }
        }
        &__description {
            font-size: 18px;
            @media screen and (max-width: 600px) {
                font-size: 12px;
            }
        }
    }

    // modifie les classes SCSS du composant Loader
    .loader {
        min-height: 300px;
    }
}
