.loader {
    display: flex;
    justify-content: center;
    align-items: center;

    &__img {
        width: 64px;
        animation: spin 2s ease-in-out infinite;
    }
}

@keyframes spin {
    100% {
        transform: rotate(360deg);
    }
}
