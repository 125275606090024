.error {
    color: $primaryColor;
    text-align: center;
    margin-top: 100px;

    &__type {
        font-weight: 700;
        font-size: 288px;
        margin-bottom: 35px;
        @media screen and (max-width: 600px) {
            font-size: 96px;
            margin-bottom: 11px;
        }
        @media screen and (min-width: 601px) and (max-width: 992px) {
            font-size: 150px;
            margin-bottom: 20px;
        }
    }

    &__description {
        font-size: 36px;
        margin-bottom: 182px;
        @media screen and (max-width: 600px) {
            font-size: 18px;
            padding: 0 37px;
            margin-bottom: 133px;
        }
        @media screen and (min-width: 601px) and (max-width: 992px) {
            font-size: 27px;
            padding: 0 15px;
        }
    }

    &__link {
        color: $primaryColor;
        font-size: 18px;
        @media screen and (max-width: 600px) {
            font-size: 14px;
        }
    }
}
