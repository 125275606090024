.gallery {
    background-color: $secondaryColor;
    border-radius: 25px;
    padding: 50px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 60px;
    @media screen and (max-width: 600px) {
        background-color: transparent;
        padding: 0;
        grid-template-columns: 1fr;
        gap: 20px;
    }
    @media screen and (min-width: 601px) and (max-width: 992px) {
        border-radius: 15px;
        grid-template-columns: 1fr 1fr;
        padding: 20px;
        gap: 20px;
    }
}

.gallery--loading {
    grid-template-columns: 1fr;
    min-height: 300px;
}
