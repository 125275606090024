.host {
    display: flex;
    gap: 10px;
    justify-content: flex-end;
    align-items: center;

    &__name {
        color: $primaryColor;
        font-size: 18px;
        text-align: right;
        @media screen and (max-width: 600px) {
            font-size: 12px;
        }
    }
    &__photo {
        border-radius: 50%;
        height: 64px;
        width: 64px;
        object-fit: cover;
        @media screen and (max-width: 600px) {
            height: 32px;
            width: 32px;
        }
    }
}
